import { useNoteContext } from '../../../modules/note/pc/NoteContext';
import { TrpcBase } from '../../../utils/trpc';
import { Input, List } from 'antd';
import type { Note } from 'server/type-file';
import { isNil } from 'lodash-es';
import { Controller } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

const MobileList = ({ onItemClick }: { onItemClick: (item: Note) => void }) => {
	const { formHook, id, action } = useNoteContext();
	const [searchParams, setSearchParams] = useSearchParams();

	const watchAllFields = formHook.watch();

	const listHook = TrpcBase.note.list.useQuery(
		{
			key: watchAllFields.key ? watchAllFields.key : '',
			page: watchAllFields.page,
		},
		{},
	);

	const { data } = listHook;
	const total = data?.total ?? 0;
	const dataSet = data?.data ?? [];

	return (
		<div className="w-screen">
			<div className="list-outside md:list-inside">
				<div className="flex flex-row items-center space-x-1">
					<Controller
						control={formHook.control}
						name="key"
						render={({ field }) => (
							<Input
								placeholder="请输入内容"
								clearable={true}
								id="searchInput"
								value={!isNil(field.value) ? field.value : ''}
								onChange={field.onChange}
							/>
						)}
					/>
				</div>
			</div>

			{/* {true && <KanBoard />} */}
			{/* <Controller
        control={formHook.control}
        name="tagId"
        render={({ field }) => (
          // @ts-expect-error type-mismatch
          <TagsSelect {...field} hasEditMode={false}></TagsSelect>
        )}
      /> */}

			<List>
				{dataSet?.map((item, index: number) => {
					return (
						<List.Item
							key={item.uuid}
							className={
								'flex cursor-pointer ' + (id === item.uuid ? 'bg-blue-50' : '')
							}
							onClick={() => {
								onItemClick?.(item);
							}}
						>
							<div className="text flex-none" />
							<div className="text flex-auto truncate">{item.title}</div>
						</List.Item>
					);
				})}
			</List>

			{/* <InfiniteScroll
        loadMore={async () => {
          // 考虑一下这里用上下页。
          return new Promise<void>((resolve) => {
            setTimeout(() => {
              resolve();
            }, 1000);
          }).then(() => {
            return formHook.setValue(
              'page',
              (formHook.getValues('page') ?? 0) + 1,
            );
          });
        }}
        hasMore={total > (dataSet?.length ?? 0)}
      /> */}
		</div>
	);
};

export default MobileList;
